import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import scui from './scui'

import '@/assets/style/common.styl';
import '@/assets/fonts/iconfont.css';

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import translate from 'i18n-jsautotranslate'

// import store from './store'

import { createI18n } from 'vue-i18n';
const i18n = createI18n({
	locale: 'zh',
	messages: {
		'zh': require('@/lang/zh'),
		'en': require('@/lang/en'),
	}
})

const app = createApp(App);
app.use(ElementPlus);
app.use(scui)
// app.use(store);
translate.setUseVersion2() //设置使用v2.x 版本
translate.selectLanguageTag.show = false //是否显示切换栏
translate.listener.start() //监控页面动态渲染的文本进行自动翻译
app.config.globalProperties.$translate = translate;
app.use(i18n).use(router).use(store).mount('#app');
