import config from "@/config"
import http from "@/utils/request"

export default {
  order: {
    add: {
      url: `${config.API_URL}/order`,
      name: "新增一个订单",
      post: async function (data) {
        return await http.post(this.url, { method: 'create.order.item', auth: true, ...data });
      }
    },
    set: {
      url: `${config.API_URL}/order`,
      name: "编辑一个订单",
      post: async function (data) {
        return await http.post(this.url, { method: 'set.order.item', auth: true, ...data });
      }
    },
    pay: {
      url: `${config.API_URL}/order`,
      name: "调起支付",
      post: async function (data) {
        return await http.post(this.url, { method: 'wx.scan.pay.pc', auth: true, ...data });
      }
    },
    paypal: {
      url: `${config.API_URL}/order`,
      name: "调起支付",
      post: async function (data) {
        return await http.post(this.url, { method: 'get.paypal.path', auth: true, ...data });
      }
    },
    paypalcallback: {
      url: `${config.API_URL}/order`,
      name: "调起支付",
      post: async function (data) {
        return await http.post(this.url, { method: 'set.callback.item', auth: true, ...data });
      }
    },
    delete: {
      url: `${config.API_URL}/order`,
      name: "批量删除订单",
      post: async function (client_id) {
        return await http.post(this.url, { method: 'del.order.list', auth: true, client_id });
      }
    },
    item: {
      url: `${config.API_URL}/order`,
      name: "获取一个订单详情",
      get: async function (client_id) {
        return await http.post(this.url, { method: 'get.order.item', auth: true, client_id });
      }
    },
    list: {
      url: `${config.API_URL}/order`,
      name: "获取订单列表",
      get: async function (data) {
        return await http.post(this.url, { method: 'get.order.list', auth: true, ...data });
      }
    }
  },
}
