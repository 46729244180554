import {
	createRouter,
	// createWebHistory,
	createWebHashHistory,
} from 'vue-router'

const routes = [
	{
		path: '/',
		// name: 'Home',
		component: () => import('@/views/home/index.vue'),
		children: [
			{
				path: '',
				component: () => import('@/views/home/home.vue')
			},
			{
				path: '/notice',
				component: () => import('@/views/notice/notice.vue')
			},
			{
				path: '/en/notice',
				component: () => import('@/views/en/notice.vue')
			}
		]
	},
	{
		path: '/login',
		component: () => import('@/views/login/index.vue'),
		children: [
			{
				path: '',
				component: () => import('@/views/login/login.vue')
			},
			{
				path: '/regist',
				component: () => import('@/views/login/regist.vue')
			},
			{
				path: '/regist1',
				component: () => import('@/views/login/regist1.vue')
			},
			{
				path: '/forgot',
				component: () => import('@/views/login/forgot.vue')
			}
		]
	},
	{
		// path: '/',
		path: '/plan',
		// name: 'Plan',
		component: () => import('@/views/plan/index.vue'),
		meta: {
			requireAuth: true,
		},
		children: [
			{
				path: '',
				component: () => import('@/views/plan/step/index.vue')
			},
			{
				path: '/survey',
				component: () => import('@/views/plan/step/survey.vue')
			},
			{
				path: '/one',
				component: () => import('@/views/plan/step/one.vue')
			},
			{
				path: '/two',
				component: () => import('@/views/plan/step/two.vue')
			},
			{
				path: '/three',
				component: () => import('@/views/plan/step/three.vue')
			},
			{
				path: '/four',
				component: () => import('@/views/plan/step/four.vue')
			},
			{
				path: '/five',
				component: () => import('@/views/plan/step/five.vue')
			},
			{
				path: '/six',
				component: () => import('@/views/plan/step/six.vue')
			},
			{
				path: '/seven',
				component: () => import('@/views/plan/step/seven.vue')
			},
			{
				path: '/eight',
				component: () => import('@/views/plan/step/eight.vue')
			},
			{
				path: '/nine',
				component: () => import('@/views/plan/step/nine.vue')
			},
			{
				path: '/ten',
				component: () => import('@/views/plan/step/ten.vue')
			},
			{
				path: '/eleven',
				component: () => import('@/views/plan/step/eleven.vue')
			},
			{
				path: '/twelve',
				component: () => import('@/views/plan/step/twelve.vue')
			},
			{
				path: '/thirteen',
				component: () => import('@/views/plan/step/thirteen.vue')
			},
			{
				path: '/fourteen',
				component: () => import('@/views/plan/step/fourteen.vue')
			},
			{
				path: '/fifteen',
				component: () => import('@/views/plan/step/fifteen.vue')
			},
			{
				path: '/success',
				component: () => import('@/views/plan/component/success.vue')
			}
		]
	},
	{
		path: '/pay',
		component: () => import('@/views/pay/index.vue')
	},
	{
		path: '/index',
		component: () => import('@/views/page/demo.vue'),
		children: [
			{
				path: '',
				component: () => import('@/views/page/home/index.vue')
			},
			{
				path: '/about',
				component: () => import('@/views/page/about/index.vue')
			},
			{
				path: '/committee',
				component: () => import('@/views/page/about/committee.vue')
			},
			{
				path: '/artist',
				component: () => import('@/views/page/about/artist.vue')
			},
			{
				path: '/pcase',
				component: () => import('@/views/page/resign/case.vue')
			},
			{
				path: '/planindex',
				component: () => import('@/views/page/resign/index.vue')
			},
			{
				path: '/resign',
				component: () => import('@/views/page/resign/sign.vue')
			},
			{
				path: '/program',
				component: () => import('@/views/page/program/index.vue')
			},
			{
				path: '/music',
				component: () => import('@/views/page/program/music.vue')
			},
			{
				path: '/musicDetail',
				component: () => import('@/views/page/program/music_detail.vue')
			},
			{
				path: '/concert_hall',
				component: () => import('@/views/page/meetings/concert_hall/index.vue')
			},
			{
				path: '/open_class',
				component: () => import('@/views/page/meetings/concert_hall/open_class.vue')
			},
			{
				path: '/meeting',
				component: () => import('@/views/page/meeting/index.vue')
			},
			{
				path: '/hotel',
				component: () => import('@/views/page/harbin/hotel.vue')
			},
			{
				path: '/harbin',
				component: () => import('@/views/page/harbin/index.vue')
			},
			{
				path: '/harbinDetail',
				component: () => import('@/views/page/harbin/detail.vue')
			},
			{
				path: '/traffic',
				component: () => import('@/views/page/harbin/traffic.vue')
			},
			{
				path: '/travel',
				component: () => import('@/views/page/harbin/travel.vue')
			},
			{
				path: '/contact',
				component: () => import('@/views/page/contact/index.vue')
			},
			{
				path: '/blank',
				component: () => import('@/views/page/blank/index.vue')
			},
			{
				path: '/userinfo',
				component: () => import('@/views/page/user/form.vue')
			},
			{
				path: '/user',
				component: () => import('@/views/page/user/index.vue')
			},
			{
				path: '/ucase',
				component: () => import('@/views/page/user/case.vue')
			},
			{
				path: '/ucaseview',
				component: () => import('@/views/page/user/caseview.vue')
			}
		]
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	// base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	if (to.meta.requireAuth) {
		let isLogin = sessionStorage.getItem('isLogin') || 0;
		if (isLogin == 1) {
			next();
		} else {
			next({ path: '/login', replace: true });
		}
	} else {
		next()
	}
})

export default router
