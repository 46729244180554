/*
 * @Descripttion: 工具集
 * @version: 1.2
 * @LastEditors: sakuya
 * @LastEditTime: 2022年5月24日00:28:56
 */

import CryptoJS from 'crypto-js';
import sysConfig from "@/config";


const tool = {}

/* localStorage */
tool.data = {
	set(key, data, datetime = 0) {
		//加密
		if (sysConfig.LS_ENCRYPTION == "AES") {
			data = tool.crypto.AES.encrypt(JSON.stringify(data), sysConfig.LS_ENCRYPTION_key)
		}
		let cacheValue = {
			content: data,
			datetime: parseInt(datetime) === 0 ? 0 : new Date().getTime() + parseInt(datetime) * 1000
		}
		return localStorage.setItem(key, JSON.stringify(cacheValue))
	},
	get(key) {
		try {
			const value = JSON.parse(localStorage.getItem(key))
			if (value) {
				let nowTime = new Date().getTime()
				if (nowTime > value.datetime && value.datetime != 0) {
					localStorage.removeItem(key)
					return null;
				}
				//解密
				if (sysConfig.LS_ENCRYPTION == "AES") {
					value.content = JSON.parse(tool.crypto.AES.decrypt(value.content, sysConfig.LS_ENCRYPTION_key))
				}
				return value.content
			}
			return null
		} catch (err) {
			return null
		}
	},
	remove(key) {
		return localStorage.removeItem(key)
	},
	clear() {
		return localStorage.clear()
	}
}

/*sessionStorage*/
tool.session = {
	set(table, settings) {
		var _set = JSON.stringify(settings)
		return sessionStorage.setItem(table, _set);
	},
	get(table) {
		var data = sessionStorage.getItem(table);
		try {
			data = JSON.parse(data)
		} catch (err) {
			return null
		}
		return data;
	},
	remove(table) {
		return sessionStorage.removeItem(table);
	},
	clear() {
		return sessionStorage.clear();
	}
}

/*cookie*/
tool.cookie = {
	set(name, value, config = {}) {
		var cfg = {
			expires: null,
			path: null,
			domain: null,
			secure: false,
			httpOnly: false,
			...config
		}
		var cookieStr = `${name}=${escape(value)}`
		if (cfg.expires) {
			var exp = new Date()
			exp.setTime(exp.getTime() + parseInt(cfg.expires) * 1000)
			cookieStr += `;expires=${exp.toGMTString()}`
		}
		if (cfg.path) {
			cookieStr += `;path=${cfg.path}`
		}
		if (cfg.domain) {
			cookieStr += `;domain=${cfg.domain}`
		}
		document.cookie = cookieStr
	},
	get(name) {
		var arr = document.cookie.match(new RegExp("(^| )" + name + "=([^;]*)(;|$)"))
		if (arr != null) {
			return unescape(arr[2])
		} else {
			return null
		}
	},
	remove(name) {
		var exp = new Date()
		exp.setTime(exp.getTime() - 1)
		document.cookie = `${name}=;expires=${exp.toGMTString()}`
	}
}

/* Fullscreen */
tool.screen = function (element) {
	var isFull = !!(document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement || document.fullscreenElement);
	if (isFull) {
		if (document.exitFullscreen) {
			document.exitFullscreen();
		} else if (document.msExitFullscreen) {
			document.msExitFullscreen();
		} else if (document.mozCancelFullScreen) {
			document.mozCancelFullScreen();
		} else if (document.webkitExitFullscreen) {
			document.webkitExitFullscreen();
		}
	} else {
		if (element.requestFullscreen) {
			element.requestFullscreen();
		} else if (element.msRequestFullscreen) {
			element.msRequestFullscreen();
		} else if (element.mozRequestFullScreen) {
			element.mozRequestFullScreen();
		} else if (element.webkitRequestFullscreen) {
			element.webkitRequestFullscreen();
		}
	}
}

/* 复制对象 */
tool.objCopy = function (obj) {
	return JSON.parse(JSON.stringify(obj));
}

/* 日期格式化 */
tool.dateFormat = function (date, fmt = 'yyyy-MM-dd hh:mm:ss') {
	date = new Date(date)
	var o = {
		"M+": date.getMonth() + 1,                 //月份
		"d+": date.getDate(),                    //日
		"h+": date.getHours(),                   //小时
		"m+": date.getMinutes(),                 //分
		"s+": date.getSeconds(),                 //秒
		"q+": Math.floor((date.getMonth() + 3) / 3), //季度
		"S": date.getMilliseconds()             //毫秒
	};
	if (/(y+)/.test(fmt)) {
		fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
	}
	for (var k in o) {
		if (new RegExp("(" + k + ")").test(fmt)) {
			fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
		}
	}
	return fmt;
}

/* 千分符 */
tool.groupSeparator = function (num) {
	num = num + '';
	if (!num.includes('.')) {
		num += '.'
	}
	return num.replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
		return $1 + ',';
	}).replace(/\.$/, '');
}

/* 常用加解密 */
tool.crypto = {
	//MD5加密
	MD5(data) {
		return CryptoJS.MD5(data).toString()
	},
	//BASE64加解密
	BASE64: {
		encrypt(data) {
			return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(data))
		},
		decrypt(cipher) {
			return CryptoJS.enc.Base64.parse(cipher).toString(CryptoJS.enc.Utf8)
		}
	},
	//AES加解密
	AES: {
		encrypt(data, secretKey, config = {}) {
			if (secretKey.length % 8 != 0) {
				console.warn("[数据管理系统 error]: 秘钥长度需为8的倍数，否则解密将会失败。")
			}
			const result = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(secretKey), {
				iv: CryptoJS.enc.Utf8.parse(config.iv || ""),
				mode: CryptoJS.mode[config.mode || "ECB"],
				padding: CryptoJS.pad[config.padding || "Pkcs7"]
			})
			return result.toString()
		},
		decrypt(cipher, secretKey, config = {}) {
			const result = CryptoJS.AES.decrypt(cipher, CryptoJS.enc.Utf8.parse(secretKey), {
				iv: CryptoJS.enc.Utf8.parse(config.iv || ""),
				mode: CryptoJS.mode[config.mode || "ECB"],
				padding: CryptoJS.pad[config.padding || "Pkcs7"]
			})
			return CryptoJS.enc.Utf8.stringify(result);
		},
	},
};


/**
 * 获取表格选中的行
 * @param val
 * @param key
 */
tool.getClientIdList = function (val, key) {
	let clients = [];
	if (Array.isArray(val)) {
		val.forEach(value => {
			clients.push(value[key]);
		});
	}

	return clients;
};

/**
 * 删除数据中指定的列表
 * @param data
 * @param id
 * @param key
 */
tool.deleteDataList = function (data, id, key) {
	for (let i = data.length - 1; i >= 0; i--) {
		if (id.indexOf(data[i][key]) !== -1) {
			data.splice(i, 1);
		}
	}
};

/**
 * 将任意对象转化为树
 * @param data
 * @param key
 * @param pid
 * @param parent
 * @returns {Array}
 */
tool.formatDataToTree = function (
	data,
	key = "menu_id",
	pid = "parent_id",
	parent = {},
	alias = {}
) {
	let map = {};
	const isSetParent = Object.keys(parent).length > 0;

	data.forEach((value) => {
		if (isSetParent && parent.value.includes(value[parent.key])) {
			value[pid] = 0;
		}

		map[value[key]] = { ...value };
	});

	const isSetAlias = Object.keys(alias).length > 0;

	if (isSetAlias) {
		data.forEach((value) => {
			Object.keys(alias).forEach((val) => {
				value[alias[val]] = value[val];
			});
			map[value[key]] = { ...value };
		});
	}

	let tree = [];
	// eslint-disable-next-line no-unused-vars
	for (let id in data) {
		// eslint-disable-next-line no-prototype-builtins
		if (!data.hasOwnProperty(id)) {
			continue;
		}

		// 对应索引
		const index = data[id][key];
		// eslint-disable-next-line no-prototype-builtins
		if (!map.hasOwnProperty(index)) {
			continue;
		}

		// 子节点压入
		if (map[index][pid] > 0) {
			if (!map[map[index][pid]]) {
				continue;
			}
			// eslint-disable-next-line no-prototype-builtins
			if (!map[map[index][pid]].hasOwnProperty("children")) {
				map[map[index][pid]].children = [];
			}
			map[map[index][pid]].children.push(map[index]);
			continue;
		}
		tree.push(map[index]);
	}
	return tree;
};

/**
 * 对象序列化，undefined和函数丢失问题
 * @param option
 * @returns {Array}
 */
tool.JSONStringify = function (option) {
	return JSON.stringify(option,
		(key, val) => {
			// 处理函数丢失问题
			if (typeof val === 'function') {
				return `${val}`;
			}
			// 处理undefined丢失问题
			if (typeof val === 'undefined') {
				return 'undefined';
			}
			return val;
		},
		2
	)
}

/**
 * 对象序列化解析
 * @param option
 * @returns {Array}
 */
tool.JSONParse = function (option) {
	return JSON.parse(option, (k, v) => {
		if (typeof v === 'string' && v.indexOf && (v.indexOf('function') > -1 || v.indexOf('=>') > -1)) {
			// eval 可能在eslint中报错，需要加入下行注释
			// eslint-disable-next-line
			return eval(`(function(){return ${v}})()`);
		}
		return v;
	});
}

/**
 * 生成随机len位数字
 * @param len
 * @param date
 * @returns {string}
 */
tool.randomLenNum = function (len, date = false) {
	let random;
	random = Math.ceil(Math.random() * 100000000000000)
		.toString()
		.substr(0, len || 4);
	return date ? random + Date.now() : random;
};

/**
 * 将数值保留2位小数返回(不会四舍五入)
 * @param value
 * @returns {string}
 */
tool.getNumber = function (value) {
	const toFixedNum = Number(value).toFixed(3);
	return value
		? toFixedNum.substring(0, toFixedNum.toString().length - 1)
		: "0.00";
};

/**
 * 判断是否手机端
 * @param value
 * @returns {string}
 */
tool.isMobile = function () {
	const regexMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
	return regexMobile.test(navigator.userAgent);
};

/**
 * 判断是否微信内
 * @param value
 * @returns {string}
 */
tool.isWeChat = function () {
	const userAgent = navigator.userAgent.toLowerCase();
	// 判断是否在微信内打开
	const isWeChat = /micromessenger/i.test(userAgent);
	return isWeChat;
};

/**
 * @description 格式化时间
 * @param {String|Number} dateTime 需要格式化的时间戳
 * @param {String} fmt 格式化规则 yyyy:mm:dd|yyyy:mm|yyyy年mm月dd日|yyyy年mm月dd日 hh时MM分等,可自定义组合 默认yyyy-mm-dd
 * @returns {string} 返回格式化后的字符串
 */
tool.timeFormat = function (dateTime = null, formatStr = 'yyyy-mm-dd') {
	let date
	// 若传入时间为假值，则取当前时间
	if (!dateTime) {
		date = new Date()
	}
	// 若为unix秒时间戳，则转为毫秒时间戳（逻辑有点奇怪，但不敢改，以保证历史兼容）
	else if (/^\d{10}$/.test(dateTime?.toString().trim())) {
		date = new Date(dateTime * 1000)
	}
	// 若用户传入字符串格式时间戳，new Date无法解析，需做兼容
	else if (typeof dateTime === 'string' && /^\d+$/.test(dateTime.trim())) {
		date = new Date(Number(dateTime))
	}
	// 处理平台性差异，在Safari/Webkit中，new Date仅支持/作为分割符的字符串时间
	// 处理 '2022-07-10 01:02:03'，跳过 '2022-07-10T01:02:03'
	else if (typeof dateTime === 'string' && dateTime.includes('-') && !dateTime.includes('T')) {
		date = new Date(dateTime.replace(/-/g, '/'))
	}
	// 其他都认为符合 RFC 2822 规范
	else {
		date = new Date(dateTime)
	}

	const timeSource = {
		'y': date.getFullYear().toString(), // 年
		'm': (date.getMonth() + 1).toString().padStart(2, '0'), // 月
		'd': date.getDate().toString().padStart(2, '0'), // 日
		'h': date.getHours().toString().padStart(2, '0'), // 时
		'M': date.getMinutes().toString().padStart(2, '0'), // 分
		's': date.getSeconds().toString().padStart(2, '0') // 秒
		// 有其他格式化字符需求可以继续添加，必须转化成字符串
	}

	for (const key in timeSource) {
		const [ret] = new RegExp(`${key}+`).exec(formatStr) || []
		if (ret) {
			// 年可能只需展示两位
			const beginIndex = key === 'y' && ret.length === 2 ? 2 : 0
			formatStr = formatStr.replace(ret, timeSource[key].slice(beginIndex))
		}
	}

	return formatStr
}

export default tool
