import config from "@/config"
import http from "@/utils/request"

export default {
  article: {
    item: {
      url: `${config.API_URL}/article`,
      name: "获取一个文章管理详情",
      get: async function (data) {
        return await http.post(this.url, { method: 'get.article.item', auth: false, ...data });
      }
    },
    list: {
      url: `${config.API_URL}/article`,
      name: "获取文章管理列表",
      get: async function (data) {
        return await http.post(this.url, { method: 'get.article.list', auth: false, ...data });
      }
    }
  }
}
