//业务配置
//会合并至this.$CONFIG
//生产模式 public/config.js 同名key会覆盖这里的配置从而实现打包后的热更新
//为避免和数据管理系统框架配置混淆建议添加前缀 MY_
//全局可使用 this.$CONFIG.MY_KEY 访问

export default {
	//是否显示第三方授权登录
	MY_SHOW_LOGIN_OAUTH: false,
	//是否显示手机号登录
	MY_SHOW_LOGIN_MOBILE: false,
	//是否显示创建账号
	MY_SHOW_LOGIN_CREATE: false,
	//是否显示修改密码
	MY_SHOW_LOGIN_UPDATEPWD: true,
	//是否显示任务模块
	MY_SHOW_TASK: false,
	//是否显示系统消息
	MY_SHOW_MSG: true,
	//是否显示系统配置
	MY_SHOW_SYSTEM_SETTING: false,
}
