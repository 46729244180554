import config from "./config"
import api from './api'
import tool from './utils/tool'
import http from "./utils/request"

// import * as elIcons from '@element-plus/icons-vue'


export default {
	install(app) {
		//挂载全局对象
		app.config.globalProperties.$CONFIG = config;
		app.config.globalProperties.$TOOL = tool;
		app.config.globalProperties.$HTTP = http;
		app.config.globalProperties.$API = api;

		//关闭async-validator全局控制台警告
		window.ASYNC_VALIDATOR_NO_WARNING = 1

		//全局代码错误捕捉
		// app.config.errorHandler = errorHandler
	}
}
