
export const lang = {
	lang: 'English',
	// 倒计时
	days: '天',
	hours: '时',
	minutes: '分',
	seconds: '秒',
	plan: '提案',
	plan_status: '提案状态',
	time: '时间',
	i_initiated: '我发起的',
	i_participated_in: '我参与的',
	info: '查看详情',
	wt_config: '特殊要求所需设备，舞台设定',
	wt_img: '上传舞台设置图',
	jmz_tips: '是否需要大会提供节奏组',
	jmz_yes: '是。协演节奏组可能会酌予收取费用，由演出者直接付予节奏组音乐家。',
	jmz_self: '是否自行邀请节奏组(若自行邀请节奏组，请填写乐团简介)',
	jmz_dahui: '要求大会提供节奏组，请上传乐队套谱或引导乐谱。',
	group_jj: '乐团履历',
	group_img: '乐团演出照片',
	pay_now: '立即支付',
	// 通知
}

