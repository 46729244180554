import { createStore } from 'vuex';

const store = createStore({
	state() {
		return {
			menuIndex: sessionStorage.getItem('menuIndex') || 0,
			showLanguage: JSON.parse(localStorage.getItem("language"))?.language || 'zh'
		}
	},
	mutations: {
		saveMenuIndex(state, data) {
			state.menuIndex = data;
		},
		saveLanguage(state, data) {
			state.showLanguage = data;
		}
	},
	actions: {
		saveMenuIndex(context, data) {
			context.commit('saveMenuIndex', data);
		},
		saveLanguage(context, data) {
			context.commit('saveLanguage', data);
		}
	},
	getters: {
		menuIndex(state) {
			return state.menuIndex;
		},
		showLanguage(state) {
			return state.showLanguage;
		}
	},
});

export default store
