import config from "@/config"
import http from "@/utils/request"

export default {
	upload: {
		url: `${config.API_URL}/upload?method=set.upload.figure`,
		name: "文件上传",
		post: async function (data, config = {}) {
			return await http.post(this.url, { auth: true, ...data }, config);
		}
	},
	setting: {
		url: `${config.API_URL}/setting?method=get.setting.bottom.list`,
		name: "系统配置",
		get: async function (data, config = {}) {
			return await http.post(this.url, { auth: false, ...data }, config);
		}
	},
	home: {
		url: `${config.API_URL}/home?method=get.index.list`,
		name: "首页数据",
		get: async function (data = {}, config = {}) {
			return await http.post(this.url, { auth: false, ...data }, config);
		}
	},
	richtext: {
		url: `${config.API_URL}/richtext`,
		name: "富文本",
		post: async function (richtext_id) {
			return await http.post(this.url, { method: 'get.richtext.item', auth: false, richtext_id });
		}
	},
	dic: {
		alias: {
			url: `${config.API_URL}/dic`,
			name: "获取字典数据",
			get: async function (data) {
				return await http.post(this.url, { method: 'get.dic.list', ...data });
			}
		}
	},
	userip: {
		url: `${config.API_URL}/home`,
		name: "获取字典数据",
		get: async function () {
			return await http.post(this.url, { method: 'get.user.ip', auth: false });
		}
	},
	ipconfig: {
		url: `${config.API_URL}/home`,
		name: "获取字典数据",
		get: async function (ip) {
			return await http.post(this.url, { method: 'get.ip.config', auth: false, ip });
		}
	},
	ads: {
		url: `${config.API_URL}/home`,
		name: "获取字典数据",
		get: async function (data) {
			return await http.post(this.url, { method: 'get.ads.item', auth: false, ...data });
		}
	}

}
