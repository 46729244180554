import config from "@/config"
import http from "@/utils/request"

export default {
  plan: {
    add: {
      url: `${config.API_URL}/plan`,
      name: "新增一个提案",
      post: async function (data) {
        return await http.post(this.url, { method: 'add.plan.item', auth: true, ...data });
      }
    },
    set: {
      url: `${config.API_URL}/plan`,
      name: "编辑一个提案",
      post: async function (data) {
        return await http.post(this.url, { method: 'set.plan.item', auth: true, ...data });
      }
    },
    delete: {
      url: `${config.API_URL}/plan`,
      name: "批量删除提案",
      post: async function (client_id) {
        return await http.post(this.url, { method: 'del.plan.list', auth: true, client_id });
      }
    },
    item: {
      url: `${config.API_URL}/plan`,
      name: "获取一个提案详情",
      get: async function (plan_id) {
        return await http.post(this.url, { method: 'get.plan.item', auth: true, plan_id });
      }
    },
    list: {
      url: `${config.API_URL}/plan`,
      name: "获取提案列表",
      get: async function (data) {
        return await http.post(this.url, { method: 'get.plan.list', auth: true, ...data });
      }
    }
  },
}
