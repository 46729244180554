import config from "@/config"
import http from "@/utils/request"

export default {
  program: {
    item: {
      url: `${config.API_URL}/program`,
      name: "获取一个节目单详情",
      get: async function (data) {
        return await http.post(this.url, { method: 'get.program.item', auth: false, ...data });
      }
    },
    list: {
      url: `${config.API_URL}/program`,
      name: "获取节目单列表",
      get: async function (data) {
        return await http.post(this.url, { method: 'get.program.list', auth: false, ...data });
      }
    }
  }
}
